import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let URL = 'https://api.mesanalyses.dz/api/'

export default new Vuex.Store({
  state: {
    info: [] ,
    isLoading: false
  },
  mutations: {
    getInfo(state) {
      state.isLoading = true
      axios.post(URL+"getInfoForSpecialSite", {
        domainName: document.location.origin
      }).then((res) => {
        state.isLoading = false
         return state.info = res.data
      }).catch((err) => {
        console.log("err =>", err);
      })
    },

    getResult(state, data) {
     
     
    }
  },
  actions: {
    getInfo({commit}, arr) {
      commit("getInfo", arr)
    },
    getResult({state,commit}, arr) {
     // state.isLoading = true
      return new Promise((resp, rej) => {
        axios.post(URL + 'getResult/resultAnalyse', {
          data: arr
        }).then((res) => {
          if (res) {
            let obj
            if (res.data.res != undefined) {
              obj = Object.keys(res.data)[0]
            } else {
              obj = Object.keys(res.data)
            }
            console.log("obj => ", obj);
            state.isLoading = false
            if (obj == "result") {
              toastr.success("Get result suucessfuly", 'Success')
              console.log('obj', res)
              resp(res.data.result)
            } else if (obj == 'Warning') {
              let arr = Object.values(res.data)[0]
              toastr.error(arr, 'Error')
              rej("Warning")
            } else {
              let arr = Object.values(res.data)[0]
              Object.values(arr).forEach(e => {
                toastr.error(e[0], 'Error')
              })
              rej("Error")
            }
          }
        }).catch((err) => {
          state.isLoading = false
          console.log("err => ", err);
          rej(err)
        })
      })
    },
  },
  modules: {
  }
})
