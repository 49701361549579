<template>
   <v-app  :style='bgSide' > 
    <v-main >
        <Header />
      <v-row >
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <router-view/>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <Footer />
    </v-main>
  </v-app> 
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    bgSide: "background:url(" + require('@/assets/bg-bottom-right.png') + ")  no-repeat fixed right , url(" + require('@/assets/bg-bottom-left2.png') + ")  no-repeat fixed left ;"
  }),
   components: {
          Header: () => import('@/views/header'),
          Footer: () => import('@/views/footer'),
     },
};
</script>
<style>
  .h-decor {
     position: relative;
     display: inline-block;
     height: 3px;
     width: 53px;
     background-color: #3368c6;
}
</style>