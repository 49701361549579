import Vue from 'vue'
import App from './App.vue'
import router from './router'
 import i18n from './i18n'
import store from './store'
import VueAxios from 'vue-axios'
import axios from 'axios'
import "toastr/build/toastr.css"
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
let URL = 'https://api.mesanalyses.dz/public/'
Vue.config.productionTip = false
window.toastr = require('toastr')
toastr.options = {
  closeButton: true,
  debug: true,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-bottom-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing:"swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
}
Vue.filter("URL1", (data) => {
  return URL + 'laboratoires/' + data
})
Vue.use(VueGoogleMaps, {
  load: {
    // v: '3.26',
    key: 'AIzaSyAh_o3rQILuAB7B_hiHxIdblSigSqsLO00',
    libraries: 'places,drawing,visualization',
  },
  draggable: true
  // installComponents: false,
})
Vue.use(VueAxios, axios)
 new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
